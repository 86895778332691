import { Link, graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import DefaultSiteLayout from '../../components/default-site-layout'
import { DefaultHelmet } from '../../components/default-helmet'

type BlogIndexPageQueryDataType = {
  site: {
    siteMetadata: {
      siteName: string
      siteDescription: string
      siteUrl: string
    }
  }
  allMdx: {
    edges: Array<{
      node: {
        id: string
        excerpt: string
        body: string
        fields: {
          slug?: string
        }
        frontmatter: {
          title: string
          date?: string
          draft?: boolean
        }
      }
    }>
  }
}
type BlogIndexContextType = { slug: string }
type BlogIndexProps = PageProps<BlogIndexPageQueryDataType, BlogIndexContextType>

export default function BlogIndex({ data }: BlogIndexProps) {
  const { edges: posts } = data.allMdx
  const { siteName, siteDescription, siteUrl } = data.site.siteMetadata

  return (
    <DefaultSiteLayout>
      <DefaultHelmet siteName={siteName} siteDescription={siteDescription} siteUrl={siteUrl} ogType="blog" title="ブログ記事一覧" />
      <main className="">
        <div className="prose prose-blue">
          <Link to={'/'} className="inline-block b-8">
            Top
          </Link>
        </div>

        <h1 className="font-mono text-4xl font-bold">Blog</h1>
        <section className="prose">
          <ul className="">
            {posts.map(({ node }: { node: any }) => {
              const { title, date, draft } = node.frontmatter
              return (
                <li key={node.id}>
                  <Link to={node.fields.slug} className="">
                    {draft ? <span className="mr-1 text-sm text-gray-400">(draft)</span> : ''}
                    {title}
                    <span className="ml-1 text-xs text-gray-400">({date})</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </section>
      </main>
    </DefaultSiteLayout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    __typename
    site {
      siteMetadata {
        siteName
        siteDescription
        siteUrl
      }
    }
    allMdx(
      filter: { frontmatter: { tags: { in: "blog" } }, fields: { draft: { eq: false } } }
      sort: { order: DESC, fields: slug }
      limit: 1000
    ) {
      edges {
        node {
          id
          excerpt
          body
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            draft
          }
        }
      }
    }
  }
`
